import React from "react";

export const GeneralOpenGraphMeta = () => {
  return (
    <>
      <meta property="og:title" content="Blog o receptech a zahrádce | Inna Valoušková" />
      <meta property="og:site_name" content="Garden and Kitchen" />
      <meta property="og:url" content="https://www.gardenandkitchen.com/" />
      <meta property="og:description" content="Recepty a zahrádka od blogerky Inny Valouškové. Blog Garden and Kitchen." />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://www.gardenandkitchen.com/assets/open-graph/garden-and-kitchen-og.jpg" />
      <meta property="og:image:width" content="1442" />
      <meta property="og:image:height" content="976" />
    </>
  );
};
